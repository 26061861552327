import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Route, Routes,BrowserRouter as Router } from 'react-router-dom'
// const express = require('express');
// const app = express();
// const port = process.env.PORT || 4000;

const routing = (
    <Router>
      <Routes>
        <Route path="*" element={<App/>} />
      </Routes>
    </Router>
  )
ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();

// app.listen(port, () => {
//   console.log(`Server is listening on port ${port}`);
// });
